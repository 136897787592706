import { Icon } from "components/Icon"
import Link from "components/Link"
import PortableText from "components/PortableText/PortableText"
import ImportantUpdatesList from "modules/frontpage/ImportantUpdatesContent"
import LatestArticlesList from "modules/frontpage/LatestArticlesContent"
import { frontpageCustomComponents } from "modules/frontpage/frontpageCustomComponents"

import Grid from "@mui/material/Grid"
import { GridSize } from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { IFrontpageCard } from "data/contentData/api/content"
import { CONTENT_TYPE, getContentType } from "lib/get-content-type"

interface IFrontpageCardProps extends IFrontpageCard {
    isChild?: boolean
}

export default function Card(props: IFrontpageCardProps) {
    const {
        types,
        image,
        imageType,
        alignment,
        preTitle,
        title,
        blockContent,
        color,
        columns,
        icon,
        templateGroup,
        take: limit,
        hasReadAccess,
        includeNew,
        includeUpdated,
        includeRevised,
        onlyImportant,
        displayDate,
        more,
        isChild = false,
        cards
    } = props

    if (hasReadAccess?.includes("NonSubscriber")) {
        return null
    }

    let content = (
        <PortableText
            value={blockContent}
            customComponents={frontpageCustomComponents(color)}
        />
    )

    const cardType = getContentType(types)

    if (
        cardType === CONTENT_TYPE.UPDATES_CARD &&
        includeNew !== undefined &&
        includeRevised !== undefined &&
        includeUpdated !== undefined &&
        limit !== undefined &&
        templateGroup !== undefined &&
        displayDate !== undefined
    ) {
        content = (
            <LatestArticlesList
                includeNew={includeNew}
                includeRevised={includeRevised}
                includeUpdated={includeUpdated}
                limit={limit}
                templateGroup={templateGroup}
                displayDate={displayDate}
                cardColor={color}
            />
        )
    }

    if (
        cardType === CONTENT_TYPE.ARTICLE_UPDATES_CARD &&
        onlyImportant !== undefined &&
        displayDate !== undefined &&
        limit !== undefined
    ) {
        content = (
            <ImportantUpdatesList
                onlyImportant={onlyImportant}
                displayDate={displayDate}
                take={limit}
                skip={0}
                cardColor={color}
            />
        )
    }

    const card = (
        <>
            {image ? (
                <StyledImage
                    columncount={columns}
                    ischild={isChild}
                    imagetype={imageType}
                    alt={title}
                    src={image.src}
                />
            ) : null}

            <StyledContentWrapper>
                <StyledContent
                    alignment={alignment}
                    ischild={isChild}>
                    {preTitle ? (
                        <Typography
                            gutterBottom
                            variant="h5"
                            component="h3">
                            {preTitle}
                        </Typography>
                    ) : null}

                    {title ? (
                        <Typography
                            variant="h3"
                            component="h2"
                            sx={{
                                display: "flex",
                                gap: 1,
                                alignItems: "center"
                            }}>
                            {icon ? <Icon name={icon} /> : null}
                            {title}
                        </Typography>
                    ) : null}

                    {content}

                    {cards && !isChild ? (
                        <Grid
                            container
                            spacing={4}>
                            {cards.map(child => (
                                <Card
                                    key={child.contentId}
                                    {...(child as IFrontpageCard)}
                                    isChild
                                />
                            ))}
                        </Grid>
                    ) : null}

                    {more ? (
                        <MoreWrapper>
                            <MoreButton
                                cardcolor={color.toString()}
                                button
                                href={more.href}
                                variant="outlined">
                                {more.text}
                            </MoreButton>
                        </MoreWrapper>
                    ) : null}
                </StyledContent>
            </StyledContentWrapper>
        </>
    )
    return (
        <Grid
            item
            md={columns as GridSize}
            xs={12}>
            {isChild ? (
                <StyledCardChild className="frontpage-card">
                    {card}
                </StyledCardChild>
            ) : (
                <StyledCard
                    className="frontpage-card"
                    cardcolor={color.toString()}
                    columncount={columns}>
                    {card}
                </StyledCard>
            )}
        </Grid>
    )
}

const MoreWrapper = styled("div")(() => ({
    alignItems: "flex-end",
    display: "flex",
    flex: 1,
    justifyContent: "flex-end"
}))

const MoreButton = styled(Link)<{ cardcolor: string }>(
    ({ cardcolor, theme }) => {
        const { primary, secondary, common, grey } = theme.palette
        const colors: any = {
            white: { background: common.white, text: secondary.main },
            default: { background: common.white, text: secondary.main },
            grey: { background: grey[100], text: grey[300] },
            primary: { background: primary.main, text: common.white },
            secondary: { background: secondary.main, text: common.white }
        }[cardcolor ?? "default"]

        return {
            backgroundColor: colors.background,
            color: colors.text,
            borderColor: colors.text,
            "&:hover": {
                backgroundColor: colors.text,
                color: colors.background
            }
        }
    }
)

const StyledCardChild = styled("div")(() => ({
    display: "flex",
    flexDirection: "column"
}))

const StyledCard = styled("div")<{ cardcolor: string; columncount: number }>(
    ({ cardcolor, columncount, theme }) => {
        const { primary, secondary, common, text, grey } = theme.palette
        const colors: any = {
            white: { background: common.white, border: primary.dark },
            default: { background: common.white, border: grey[300] },
            grey: { background: grey[100], border: grey[300] },
            primary: { background: primary.main, text: common.white },
            secondary: { background: secondary.main, text: common.white }
        }[cardcolor ?? "default"]
        return {
            color: colors.text ?? text.primary,
            backgroundColor: colors.background,
            border: !colors.border ? undefined : `2px solid ${colors.border}`,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflow: "hidden",
            [theme.breakpoints.up("sm")]: {
                flexDirection: columncount <= 4 ? "column" : "row"
            }
        }
    }
)

const StyledContentWrapper = styled("div")(() => ({
    display: "flex",
    flex: 1
}))

const StyledContent = styled("div")<{ alignment: string; ischild: boolean }>(
    ({ alignment, ischild, theme }) => {
        return {
            display: "flex",
            width: "100%",
            flexDirection: "column",
            padding: ischild ? 0 : theme.spacing(4),
            textAlign: alignment === "center" ? "center" : "left"
        }
    }
)

const StyledImage = styled("img")<{
    columncount: number
    ischild: boolean
    imagetype: string
}>(({ columncount, ischild, imagetype, theme }) => {
    return {
        flex: columncount > 4 ? "1" : undefined,
        maxWidth: "100%",
        minHeight: ischild ? "initial" : "150px",
        objectFit: imagetype === "photo" ? "cover" : "none",
        overflow: "hidden",

        [theme.breakpoints.up("md")]: {
            maxWidth: columncount >= 12 ? "32.4%" : "100%",
            objectFit: imagetype === "photo" ? "cover" : "contain",
            margin: imagetype === "photo" ? undefined : theme.spacing(0, 6)
        }
    }
})
