import { Icon } from "components/Icon"
import { general } from "config/config"
import useUser from "hooks/useUser"
import { useInteractions } from "modules/interactions/InteractionsContext"
import { useRouter } from "next/router"

import Fab from "@mui/material/Fab"
import Tooltip from "@mui/material/Tooltip"
import { red } from "@mui/material/colors"
import { useTheme } from "@mui/material/styles"

import { isEditor } from "lib/auth"

export function PreviewFAB() {
    const router = useRouter()
    const { isPreview, asPath } = router
    const preview = isPreview || asPath.includes("preview")
    const { user } = useUser()
    const theme = useTheme()

    if (!isEditor(user?.roles)) {
        return null
    }

    let href
    if (preview) {
        if (asPath === "/preview") href = "/"
        else href = asPath.replace("/preview", "")
    } else {
        href = `/preview${asPath}`
    }

    return (
        <Tooltip title={preview ? "Show published" : "Show unpublished"}>
            <Fab
                href={href}
                size="medium"
                style={{
                    backgroundColor: preview
                        ? theme.palette.epiOrange.main
                        : "#fff"
                }}>
                <Icon
                    name="visibility"
                    fontSize="small"
                    htmlColor={preview ? "#fff" : theme.palette.epiOrange.main}
                />
            </Fab>
        </Tooltip>
    )
}

export function EditFAB() {
    const theme = useTheme()
    const { contentId } = useInteractions()
    const { user } = useUser()

    if (!isEditor(user?.roles) || !contentId) {
        return null
    }

    const url = new URL(general.appHost)
    const editUrl = `https://cms.${url.host}/editor/edit/${contentId}`

    return (
        <Tooltip title="Edit in CMS">
            <Fab
                href={editUrl}
                size="medium"
                style={{ backgroundColor: theme.palette.epiOrange.main }}>
                <Icon
                    name="edit"
                    fontSize="small"
                    htmlColor="#fff"
                />
            </Fab>
        </Tooltip>
    )
}

export function DebugFAB() {
    const { user } = useUser()
    const { openDebug } = useInteractions()

    if (!isEditor(user?.roles)) {
        return null
    }

    return (
        <Tooltip title="Toggle debug drawer">
            <Fab
                onClick={openDebug}
                size="medium"
                style={{ backgroundColor: red[700] }}>
                <Icon
                    fontSize="small"
                    htmlColor="#fff"
                    name="settings"
                />
            </Fab>
        </Tooltip>
    )
}
