import Main from "components/Layout/Main"
import { SkipNavContent } from "components/SkipNav"
import useUser from "hooks/useUser"
import Card from "modules/frontpage/Card"

import Grid from "@mui/material/Grid"

import { IFrontpageCard } from "data/contentData/api/content"
import { isEditor, isSubscriber } from "lib/auth"

export default function Frontpage({ cards }: { cards: IFrontpageCard[] }) {
    const { user } = useUser()
    const userHasAccess = isSubscriber(user?.roles) || isEditor(user?.roles)

    if (!cards) {
        return null
    }

    return (
        <Main>
            <Grid
                container
                spacing={2}
                sx={{ pt: 2 }}>
                <SkipNavContent />
                {cards.map(card => {
                    if (
                        userHasAccess &&
                        card.allowedRoles?.includes("NonSubscriber")
                    )
                        return null
                    if (
                        !userHasAccess &&
                        card.allowedRoles?.includes("Subscriber")
                    )
                        return null

                    return (
                        <Card
                            key={card.contentId}
                            {...card}
                        />
                    )
                })}
            </Grid>
        </Main>
    )
}
