import ConfirmUserDetails from "components/ConfirmUserDetails/ConfirmUserDetails"
import Layout from "components/Layout"
import { Banner } from "components/banner"
import useUser from "hooks/useUser"
import Frontpage from "modules/frontpage"
import { InteractionsProvider } from "modules/interactions/InteractionsContext"
import { NextPage } from "next/app"
import { useEffect } from "react"

import { IFrontpage, getFrontpage } from "data/contentData/api/content"
import { useGtmCustomVariables } from "lib/gtag"
import { isLocalStorageSupported } from "lib/storage/is-localstorage-supported"

interface IProps {
    frontpage: IFrontpage
}

const Index: NextPage<IProps> = ({ frontpage }) => {
    useGtmCustomVariables(frontpage)
    useIntroRedirect()

    return (
        <Layout>
            <InteractionsProvider contentId={frontpage.contentId}>
                <Banner />
                <ConfirmUserDetails />
                <Frontpage cards={frontpage.main} />
            </InteractionsProvider>
        </Layout>
    )
}

Index.breadcrumb = false

export default Index

export const getStaticProps = async () => {
    const data = await getFrontpage()
    const revalidate = Number(process.env.REVALIDATE_STATIC_PROPS)

    return {
        props: {
            frontpage: data
        },
        revalidate
    }
}

function useIntroRedirect() {
    const { user, isLoading } = useUser()
    const loadingOrUser = isLoading || !!user
    useEffect(() => {
        if (!isLocalStorageSupported()) {
            return
        }
        if (
            window.location.search.includes("forceLogin=true") ||
            process.env.NODE_ENV !== "production" ||
            process.env.NEXT_PUBLIC_ID !== "medibas-se" ||
            Date.now() < Number(localStorage.getItem("intro-visited")) ||
            loadingOrUser
        ) {
            return
        }
        const oneWeekFromNow = Date.now() + 1000 * 60 * 60 * 24 * 7
        localStorage.setItem("intro-visited", oneWeekFromNow.toString())
        window.location.href = `https://intro.${window.location.host}`
    }, [loadingOrUser])
}
